import React, { useContext } from 'react';
import axios from 'axios';
import {Alert, Button, Card, Form, Input, message} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import UserProvider from '../context/UserContext';

const ForgotPass = () => {
  const navigate = useNavigate();
  const onFinish = async (values) => {
      try {
          await axios.post('/api/users/forgot-pass', values);
          message.success('Password reset email sent! Follow the instructions in the email to reset your password.');
      } catch (err) {
          message.error(err.message);
      }
  };

  return (
    <Card style={{ textAlign: "center", width: "50%", margin: "96px auto" }}>
      <Alert
          style={{ textAlign: "left" }}
          showIcon
          message="Request Password Change"
          description="Please enter your email address below. We will send you an email with a link to reset your password."
          type="info"
      />
      <Form
        style={{ marginTop: '24px' }}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 8,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 8,
          }}
        >
        <Button type="primary" htmlType="submit">
          SUBMIT
        </Button>
        <Button type="link" htmlType="button" onClick={() => navigate('/login')}>
          Back to login
        </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default ForgotPass;
