import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';

// Components
import { Footer } from 'antd/es/layout/layout';
import Text from 'antd/es/typography/Text';
import { CopyrightOutlined } from '@ant-design/icons';
import NavBar from '../Layout/NavBar/NavBar';
import Home from '../components/Home';
import Login from '../components/Login';
import RequireAuth from '../components/RequireAuth';
import Register from '../components/Register';
import AgentList from '../components/agent/AgentList';
import TransactionList from '../components/transaction/TransactionList';
import ContractList from '../components/contract/ContractList';
import ContractInvoiceList from '../components/contract/ContractInvoiceList';
import ProjectList from '../components/project/ProjectList';
import ProjectContractList from '../components/project/ProjectContractList';
import ProjectInvoiceList from '../components/project/ProjectInvoiceList';
import UserProvider from '../context/UserContext';
import ForgotPassword from "../components/ForgotPassword";
import ChangePassword from "../components/ChangePassword";

function Routez() {
  const { userData } = useContext(UserProvider.context);

  const { Content } = Layout;
  return (
    <Layout className="layout">
      <NavBar />
      <Content style={{ padding: '0 50px', background: '#fff' }}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ChangePassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<RequireAuth><Home /></RequireAuth>} />
          <Route path="/agents" element={<RequireAuth><AgentList /></RequireAuth>} />
          <Route path="/projects" element={<RequireAuth><ProjectList /></RequireAuth>} />
          <Route path="/projects/:id/contracts" element={<RequireAuth><ProjectContractList /></RequireAuth>} />
          <Route path="/projects/:id/invoices" element={<RequireAuth><ProjectInvoiceList /></RequireAuth>} />
          <Route path="/transactions" element={<RequireAuth><TransactionList /></RequireAuth>} />
          <Route path="/contracts" element={<RequireAuth><ContractList /></RequireAuth>} />
          <Route path="/invoices" element={<RequireAuth><ContractInvoiceList /></RequireAuth>} />
        </Routes>
      </Content>
      <Footer style={{ background: 'white', textAlign: 'right' }}>
        <Text code>
          <CopyrightOutlined />
          2025 BrokerPlan.pro by CrossPoint
        </Text>
      </Footer>
    </Layout>
  );
}

export default Routez;
