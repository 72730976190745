import React, { useContext } from 'react';
import axios from 'axios';
import { Button, Card, Form, Input, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import UserProvider from '../context/UserContext';

const Login = (props) => {
  const { getMe } = useContext(UserProvider.context);
  const navigate = useNavigate();
  const { state } = useLocation();
  let redirectPath = "/";
  if (state && state.path && state.path !== "/login") {
    redirectPath = state.path;
  }

  const onFinish = async (values) => {
    try {
      await axios.post('/api/users/login', values);
      const userData = await getMe();
      message.success(`Login success! Welcome back, ${userData.name}`);
      navigate(redirectPath);
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <Card style={{ textAlign: "center", width: "50%", margin: "96px auto" }}>
      <Form
        style={{ marginTop: '24px' }}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 8,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 8,
          }}
        >
          <Button type="primary" htmlType="submit">
            LOGIN
          </Button>
          <Button type="link" htmlType="button" onClick={() => navigate('/forgot')}>
            Forgot password?
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default Login;
