import React, { Component, useContext } from 'react';
import axios from 'axios';
import {
  Table,
  Alert,
  Space,
  Button,
  PageHeader,
  Tag,
  Breadcrumb, Input, Tooltip,
} from 'antd';
import {
  AppstoreOutlined, SnippetsOutlined, UndoOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import LoadingFeedback from '../LoadingFeedback';
import AgentFormDrawer from './AgentFormDrawer';
import AgentInvoicesFormDrawer from './AgentInvoicesFormDrawer';
import AgentContributionsFormDrawer from './AgentContributionsFormDrawer';
import { formatNumberEuro } from '../../utils';
import UserProvider from '../../context/UserContext';

export default class AgentList extends Component {
  static contextType = UserProvider.context;

  constructor(props) {
    super(props);

    this.state = {
      baseData: null,
      filteredData: null,
      error: '',
      selectedRowKeys: [],
      agentModalVisible: false,
      agentIdToEdit: null,
      agentInvoiceModalVisible: false,
      agentContributionsModalVisible: false,
      agentToAddInvoice: null,
      agentToSeeContributions: null
    };
  }

  search = (value) => {
    const { baseData } = this.state;

    const filteredData = baseData.filter((o) => Object.keys(o).some((k) => String(o[k])
      .toLowerCase()
      .includes(value.toLowerCase())
        // search in the away group's name as well
        || String(o[k]?.name)
          .toLowerCase()
          .includes(value.toLowerCase())));

    this.setState({ filteredData });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    try {
      const agents = await axios('/api/agents/');
      const userData = this.context?.userData;
      if (userData.level !== 0) {
        this.setState({ baseData: agents.data });
      }
      else {
        const filteredData = agents.data.filter((agt) => agt._id === userData._id);
        this.setState({ baseData: filteredData });
      }
    } catch (err) {
      this.setState({ error: err.message });
    }
  }

  removeObject = async (id) => {
    try {
      await axios.delete(`/api/agents/${id}`);
      const agents = await axios('/api/agents/');
      this.setState({ baseData: agents.data });
    } catch (err) {
      this.setState({ error: err.message });
    }
  };

  render() {
    const {
      error, baseData, filteredData, selectedRowKeys,
      agentModalVisible, agentIdToEdit, agentToAddInvoice,
      agentInvoiceModalVisible, agentContributionsModalVisible, agentToSeeContributions
    } = this.state;
    if (!baseData) return <LoadingFeedback />;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => (
          <Button
            type="link"
            onClick={() => {
              this.setState({agentIdToEdit: record._id, agentModalVisible: true});
            }}
          >
            {record.name}
          </Button>
        ),
        sorter: (a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }),
      },
      {
        title: 'Level',
        dataIndex: 'level',
        key: 'status',
        render: (text, record) => {
          if(record.level === -1) return 'Inactive';
          if(record.level === 0) return 'Agent';
          if(record.level === 1) return 'Dept. Head';
          return 'Admin';
        },
        responsive: ['md','lg', 'xl', 'xxl'],
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => a.status.localeCompare(b.status, undefined, { sensitivity: 'base' }),
        responsive: ['md','lg', 'xl', 'xxl']
      },
      {
        title: 'Balance',
        dataIndex: 'accumulated',
        key: 'accumulated',
        render: (text, record) => (
          <Button
            type="link"
            onClick={() => {
              this.setState({agentToSeeContributions: record, agentContributionsModalVisible: true});
            }}
          >
            {formatNumberEuro(record.accumulated)}
          </Button>
        ),
        sorter: (a, b) => a.accumulated - b.accumulated,
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 120,
        align: 'right'
      },
      {
        title: 'Current FEE',
        dataIndex: 'fee',
        key: 'fee',
        render: (text, record) => `${record.fee}%`,
        sorter: (a, b) => a.fee - b.fee,
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 120,
        align: 'right'
      },
      {
        title: 'Earned',
        key: 'earned',
        render: (text, record) => formatNumberEuro(record.earned),
        sorter: (a, b) => a.earned - b.earned,
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 120,
        align: 'right'
      },
      {
        title: 'Billed',
        dataIndex: 'billed',
        key: 'billed',
        render: (text, record) => formatNumberEuro(record.agentinvoices?.totalValue),
        sorter: (a, b) => (a.agentinvoices?.totalValue || 0) - (b.agentinvoices?.totalValue),
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 120,
        align: 'right'
      },
      {
        title: 'Target',
        dataIndex: 'target',
        key: 'target',
        render: (text, record) => formatNumberEuro(record.target),
        sorter: (a, b) => a.target - b.target,
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 120,
        align: 'right'
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (text, record) => (
          <Space size="middle">
            <Tooltip title="See transactions and add invoice">
              <Button shape="circle" icon={<SnippetsOutlined />} onClick={() => {
                this.setState({agentToAddInvoice: record, agentInvoiceModalVisible: true});
              }} />
            </Tooltip>
            {/*<Button shape="circle" icon={<DeleteOutlined />} onClick={() => this.removeObject(record._id)} />*/}
          </Space>
        ),
        responsive: ['lg', 'xl', 'xxl'],
        width: 120,
        align: 'center'
      },
    ];
    const tableData = (filteredData || baseData).map((item) => ({ key: item._id, ...item }));
    return (
      <>
        {error ? <Alert message={error} type="error" /> : null}
        <Breadcrumb style={{ padding: '16px 24px 0' }}>
          <Breadcrumb.Item>
            <Link to="/">
              <AppstoreOutlined />
              {' '}
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <UserOutlined />
            {' '}
            Agent list
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          title="Agent List"
          tags={(
            <Tag>
              {tableData.length}
              {' '}
              item(s)
            </Tag>
          )}
          className="site-page-header"
          extra={[
            (hasSelected ? <Tag>{`${selectedRowKeys.length} agent(s) selected`}</Tag> : null),
            // <Button
            //   key="1"
            //   type="primary"
            //   icon={<UserAddOutlined />}
            //   onClick={() => {
            //     this.setState({ agentModalVisible: true });
            //   }}
            // >
            //   Add Agent
            // </Button>,
            <Tooltip key="2" title="Reset balance and earned for all agents">
              <Button
                icon={<UndoOutlined />}
                disabled
                // onClick={() => {
                //   this.setState({ agentModalVisible: true });
                // }}
              >
                Yearly reset
              </Button>
            </Tooltip>
          ]}
        />
        <Input.Search
          style={{ marginBottom: '16px' }}
          placeholder="Search by..."
          enterButton
          onSearch={this.search}
        />
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          size="middle"
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total) => `Total: ${total} items`,
          }}
        />
        <AgentFormDrawer
          id={agentIdToEdit}
          onClose={async () => {
            this.setState({agentModalVisible: false, agentIdToEdit: null});
            await this.loadData();
          }}
          visible={agentModalVisible}
        />
        <AgentInvoicesFormDrawer
          agent={agentToAddInvoice}
          onClose={async () => {
            this.setState({agentInvoiceModalVisible: false, agentToAddInvoice: null});
            await this.loadData();
          }}
          visible={agentInvoiceModalVisible}
        />
        <AgentContributionsFormDrawer
          agent={agentToSeeContributions}
          onClose={async () => {
            this.setState({agentContributionsModalVisible: false, agentToSeeContributions: null});
          }}
          visible={agentContributionsModalVisible}
        />
      </>
    );
  }
}
