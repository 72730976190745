import React from 'react';
import axios from 'axios';
import { Alert, Button, Card, Form, Input, message, Select } from 'antd';
import {useNavigate, useParams} from "react-router-dom";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { token} = useParams();

  const onFinish = async (values) => {
    try {
      await axios.post('/api/users/reset-password', { password: values.password, token });
      message.success('Password successfully changed! You have been redirected to the login page...');
      navigate("/login");
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <>
      <Card style={{ textAlign: "center", width: "50%", margin: "96px auto" }}>
        <Form
          style={{ marginTop: '24px' }}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 8,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="New Password"
            name="password"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please input your new password!',
              },
              {
                min: 6,
                message: 'Password must be minimum 6 characters.',
              }
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The new password that you entered does not match!'));
                  },
                }),
              ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 8,
            }}
          >
            <Button type="primary" htmlType="submit">
              CHANGE PASSWORD
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  )
}

export default ChangePassword;
